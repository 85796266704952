import moment from 'moment'
import { AccountPermission } from '../common/enum'

export const addSessionStorage = (key: string, data: any) => {
  sessionStorage.setItem(key, data)
}

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key)
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

export const convertUTCtoLocalTimezone = (utcDate: string) => {
  const formattedDate = moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss')

  return new Date(formattedDate)
}

/**
 * Checks if the provided permission exists in the account permissions array.
 * @param accountPermissions The array of permissions that the account has
 * @param permission The permission to verify
 * @returns boolean
 */
export const verifyPermission = (accountPermissions: Array<AccountPermission>, permission: AccountPermission) => {
  return accountPermissions.includes(permission)
}

export const getDobYear = (fullDOB: string) => {
  const regex = new RegExp(/^\d{4}/)
  const match = fullDOB.match(regex)

  if (match) {
    return match[0]
  }

  return fullDOB
}

export const removeDashSpaceSpecialChar = (text: string) =>
  text
    .replace(/-/g, '')
    .replace(/ /g, '')
    .replace(/\//g, '')
    .replace(/[`~!@#$%^&*()_|+\-=?;:',.<>{}[\]\\]/gi, '')
