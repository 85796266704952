import React from 'react'
import FlagHandler from '../FlagHandler'
import SearchItemDetails from '../SearchItemDetails'
import { SearchResultI } from '../../../../components/ComponentInterface'

export const TableBodyRows = ({
  data,
  validationBrokerData,
  handleEditClick,
}: {
  data: SearchResultI[]
  validationBrokerData?: any
  handleEditClick: (key: string) => void
}) => (
  <>
    {data.map((item) => (
      <React.Fragment key={item.email}>
        <FlagHandler flagText={item.reset_status} />
        <tr className="search-details-row">
          <td colSpan={7}>
            <SearchItemDetails
              searchData={item}
              validationBrokerData={validationBrokerData}
              handleEditClick={handleEditClick}
            />
          </td>
        </tr>
      </React.Fragment>
    ))}
  </>
)
