import React, { FC, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import i18n from '../../../../i18n'
import { ReactComponent as Close } from '../../../../asset/icon-close-white.svg'
import { Button, TextInput } from '../../../../components'

import useDeactiveAccount from '../../hooks/useDeactiveAccount'
import SelectInput from '../../../../components/inputs/SelectInput'

type DeactiveAccountModalProps = {
  isOpen: boolean
  close: () => void
  userId?: string
  onFinish: () => void
}

const DeactiveAccountModal: FC<DeactiveAccountModalProps> = ({ isOpen, close, userId, onFinish }) => {
  const { handleDeacticeAccount, isLoading } = useDeactiveAccount(userId || '')
  const [adicionalNotes, setAdicionalNotes] = useState('')
  const [selectedReason, setSelectedReason] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isError && selectedReason) {
      setIsError(false)
    }
  }, [selectedReason, isError])

  const handleAdicionalNotes = (e) => {
    const { value } = e?.target
    setAdicionalNotes(value)
  }

  const handleSelectChange = (value) => {
    setSelectedReason(value)
  }

  const onClose = () => {
    setAdicionalNotes('')
    setSelectedReason('')
    setIsError(false)

    if (close) close()
  }

  const handleDeactiveWithReason = async () => {
    if (!selectedReason) {
      setIsError(true)
      return
    }

    const reasonWithNotes = `${selectedReason}${!!adicionalNotes ? ` - ${adicionalNotes}` : ''}`

    await handleDeacticeAccount(reasonWithNotes)
    await onFinish()
    onClose()
  }

  const options = [
    {
      value: i18n.t('SearchPage.DeactivateAccount.reason-options.no-longer'),
      label: i18n.t('SearchPage.DeactivateAccount.reason-options.no-longer'),
    },
    {
      value: i18n.t('SearchPage.DeactivateAccount.reason-options.duplicate'),
      label: i18n.t('SearchPage.DeactivateAccount.reason-options.duplicate'),
    },
    {
      value: i18n.t('SearchPage.DeactivateAccount.reason-options.other'),
      label: i18n.t('SearchPage.DeactivateAccount.reason-options.other'),
    },
  ]

  return (
    <ReactModal overlayClassName="ReactModal__Overlay" isOpen={isOpen} className="modal reset-2fa-modal">
      <div className="header">
        <h2>{i18n.t('SearchPage.DeactivateAccount.title')}</h2>
        <button data-testid="deactive-account-close" onClick={onClose}>
          <Close />
        </button>
      </div>
      <div className="content">
        <p>{i18n.t('SearchPage.DeactivateAccount.description')}</p>
        <div>
          <SelectInput
            value={selectedReason}
            onChange={handleSelectChange}
            options={options}
            label={i18n.t('SearchPage.DeactivateAccount.reason')}
            required
            dataTestId={'reason-for-deleting'}
            isError={isError}
          />
          <TextInput
            name="aditional-notes"
            onChange={handleAdicionalNotes}
            value={adicionalNotes}
            label={i18n.t('SearchPage.DeactivateAccount.notes')}
            errorMessage={'Error'}
            dataTestId={'aditional-notes'}
            errorDataTestId={'aditional-notes-error'}
          />
        </div>
        <div className="actions">
          <Button dataTestId="deactive-account-cancel" onClick={onClose} text={i18n.t('cancel')} />
          <Button
            dataTestId="deactive-account-action"
            onClick={handleDeactiveWithReason}
            className="border-btn"
            isLoading={isLoading}
            text={i18n.t('SearchPage.DeactivateAccount.btn-action')}
          />
        </div>
      </div>
    </ReactModal>
  )
}

export default DeactiveAccountModal
