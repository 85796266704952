import { AccountPermission } from '../../../common/enum'
import { ConfigI, Features, ProfileI } from '../../../components/ComponentInterface'
import { useContext } from '../../../context'
import { verifyPermission } from '../../../utils'

type RowType = {
  label: string
  editable?: boolean
}

type ColumnType = {
  title: string
  rows: Array<RowType>
}

const useSearchTableLayout = () => {
  const {
    state: { config, profile },
  }: {
    state: { config: ConfigI; profile: ProfileI }
  } = useContext()
  const permissions = profile?.permissions || []
  const LAYOUT: Array<ColumnType> = [
    {
      title: 'SearchPage.profile-information',
      rows: [
        {
          label: 'first_name',
        },
        {
          label: 'middle_name',
        },
        {
          label: 'last_name',
        },
        {
          label: 'birth_date',
        },
        {
          label: 'address_line_1',
        },
        {
          label: 'address_line_2',
        },
        {
          label: 'city',
        },
        {
          label: 'postal_code',
        },
        {
          label: 'region',
        },
        {
          label: 'country',
        },
        {
          label: 'id_card_number',
        },
        {
          label: 'id_card_expiry',
        },
      ],
    },
    {
      title: 'SearchPage.health-card-information',
      rows: [{ label: 'health_card_number' }, { label: 'health_card_expiry' }],
    },
    {
      title: 'SearchPage.account-information',
      rows: [
        {
          label: 'email',
          editable:
            !!config.feature_flags_enabled?.[Features.ResetEmail] &&
            verifyPermission(permissions, AccountPermission.canChangeEmail),
        },
        { label: 'authenticator' },
      ],
    },
  ]
  const MAX_ROWS = Math.max(...LAYOUT.map((column) => column.rows.length))
  return { LAYOUT, MAX_ROWS }
}

export default useSearchTableLayout
