const API_ERROR = 'API_ERROR'
const SESSION_TIMEOUT = 'SESSION_TIMEOUT'
const PENDING_REVIEW = 'PENDING_REVIEW'
const OAUTH_PROVIDERS_ERROR = 'OAUTH_PROVIDERS_ERROR'
const VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED'
const NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS'

const ERRORS = {
  HEALTH_CARD_EXISTS: 'HEALTH_CARD_EXISTS',
  USER_UNVERIFIED: 'USER_UNVERIFIED',
}

export { API_ERROR, SESSION_TIMEOUT, PENDING_REVIEW, OAUTH_PROVIDERS_ERROR, VERIFICATION_CODE_EXPIRED, NOT_ENOUGH_PERMISSIONS, ERRORS }
