import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import ReactModal from "react-modal";
import { Button } from "../../../../components";
import { validateEmail } from "../../../../components/validate";
import i18n from "../../../../i18n";
import { useTriggeredResetEmail } from "../../../../services/AdminServices";
import { ReactComponent as Close } from "../../../../asset/icon-close-white.svg";

type ResetEmailModalProps = {
  isOpen: boolean;
  close: (newEmail?: string) => void;
  userId?: string;
  userEmail?: string;
  resetExpiryDuration?: string;
};

const ResetEmailModal: FC<ResetEmailModalProps> = ({
  isOpen,
  close,
  userId,
  userEmail,
  resetExpiryDuration,
}) => {
  const { resetEmail, isLoading } = useTriggeredResetEmail(userId || "");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleResetEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!validateEmail(email)) {
        setEmailError("SearchPage.ErrorMessages.invalid-email");
        return;
      }
      if (userEmail === email) {
        setEmailError("same-email-error");
        return;
      }
      setEmailError("");
      const { error } = await resetEmail({ data: { email } });
      if (error.isConflictError) {
        setEmailError("email-already-exists-error");
        return;
      }
      close(email);
    } catch (e) {
      console.error("Error when resetting 2FA");
    }
  };
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  return (
    <ReactModal
      overlayClassName="ReactModal__Overlay"
      isOpen={isOpen}
      className="modal reset-2fa-modal"
      onAfterClose={() => setEmail("")}
      testId="reset-email-modal"
    >
      <div className="header">
        <h2>{i18n.t("reset-item", { item: i18n.t("email-address") })}</h2>
        <button data-testid='reset-email-close' onClick={() => close()}>
          <Close />
        </button>
      </div>
      <div className="content">
        <form onSubmit={handleResetEmail}>
          <p>
            <strong>{i18n.t("current-email-address")}</strong> <span data-testid='reset-email-current-email'>{userEmail}</span>
          </p>
          <div className="input-container">
            <label>
              <strong>{i18n.t("New Email Address")}</strong>
            </label>
            <input type="email" data-testid='reset-email-input' onChange={onEmailChange} value={email} />
            {!!emailError && (
              <span className="error">{i18n.t(emailError)}</span>
            )}
          </div>
          <p>
            {i18n.t("reset-email-modal-warning", {
              duration: resetExpiryDuration,
            })}
          </p>
          <div className="actions">
            <Button dataTestId="reset-email-cancel" onClick={() => close()} text={i18n.t("cancel")} />
            <Button
              type="submit"
              onClick={() => {}}
              className="border-btn"
              isLoading={isLoading}
              dataTestId="reset-email-submit"
              text={i18n.t("reset")}
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default ResetEmailModal;
