import parse from 'html-react-parser'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Header, ModalComp, Notification, SearchForm } from '../../components'
import {
  ConfigI,
  Features,
  PersonHistoryRequest,
  ProfileI,
  SearchInputI,
  SearchResultI,
} from '../../components/ComponentInterface'
import { validateEmail } from '../../components/validate'
import { useContext } from '../../context'
import {
  useTriggeredSearch,
  useTriggeredFetchVerification,
  useTriggeredStartVerification,
  useTriggeredFetchVerificationCode,
  useTriggeredFetchValidationBrokerData,
  useTriggeredGetPersonDetails,
  useTriggeredPersonHistory,
} from '../../services/AdminServices'
import { SESSION_TIMEOUT, API_ERROR, VERIFICATION_CODE_EXPIRED, NOT_ENOUGH_PERMISSIONS } from '../../utils/Constants'
import { PrintSetupInstructionsRoute } from '../../utils/routes'
import { updatedDataFromValidator } from '../../utils/SearchPage'
import useHealthCardVerification from './hooks/useHealthCardVerification'
import { AccountPermission, AccountStatus, VerificationStatus } from '../../common/enum'
import { updateError } from '../../context/actions/ConfigActions'
import i18n from '../../i18n'
import Reset2FAModal from './Components/modal/Reset2FAModal'
import ResetEmailModal from './Components/modal/ResetEmailModal'
import SearchResultTable from './Components/SearchResultTable'
import moment from 'moment'
import { verifyPermission } from '../../utils'
import useActionsOptions from './hooks/useActionsOptions'
import AccountHistoryTable from './Components/AccountHistoryTable'
import DeactiveAccountModal from './Components/modal/DeactiveAccountModal'
import ReactiveAccountModal from './Components/modal/ReactiveAccountModal'

const convertSecondsToHumanReadableTime = (seconds: number) => moment.duration(seconds, 'seconds').humanize()

const SearchPage = () => {
  const {
    state: { error, config, profile },
    dispatch,
  }: {
    state: { error: any; config: ConfigI; profile: ProfileI }
    dispatch: any
  } = useContext()
  const permissions = useMemo(() => profile?.permissions || [], [profile])

  const [searchData, setSearchData] = useState<SearchInputI>({
    rin: '',
    email: '',
  })
  const [personHistoryPage, setPersonHistoryPage] = useState(0)

  const { useSearch: searchApi, isLoading } = useTriggeredSearch(searchData)
  const [searchResults, setSearchResults] = useState<Array<SearchResultI>>([])
  const [persronHistory, setPersronHistory] = useState<PersonHistoryRequest>()

  const { useFetchVerification: fetchVerification } = useTriggeredFetchVerification(searchResults[0]?.verification_id)
  const { useStartVerification: startVerification, isLoading: isLoadingStartVerification } =
    useTriggeredStartVerification()
  const { useFetchVerificationCode: fetchVerificationCode } = useTriggeredFetchVerificationCode(
    searchResults[0]?.verification_id,
  )
  const { useFetchValidationBrokerData: fetchValidationBrokerData } = useTriggeredFetchValidationBrokerData(
    searchResults[0]?.user_id,
  )

  const { redirectionIDVHealthCard } = useHealthCardVerification(searchResults[0]?.user_id)
  const { useGetPersonDetails: getPersonDetails } = useTriggeredGetPersonDetails(searchResults[0]?.user_id)

  const { getPersonHistory, isLoading: isLoadingPersonHistory } = useTriggeredPersonHistory()
  const [showAddHealthCard, setShowAddHealthCard] = useState<boolean>(false)
  const [validationBrokerData, setValidationBrokerData] = useState<any>({})

  const { setActionOptions, resetActionOptions } = useActionsOptions()

  // modal
  const [isConfirmDetailsModalOpen, setConfirmDetailsModalOpen] = useState(false)
  const [isReset2FAModalOpen, setReset2FAModalOpen] = useState(false)
  const [isResetEmailModalOpen, setResetEmailModalOpen] = useState(false)
  const [isDeacticeAccountModalOpen, setDeacticeAccountModalOpen] = useState(false)
  const [isReacticeAccountModalOpen, setReacticeAccountModalOpen] = useState(false)

  const [searchingError, setSearchingError] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const [errorObj, setErrorObj] = useState<any>({})

  const handleIDVHealthCard = async () => {
    await redirectionIDVHealthCard()
    setSearchData({ rin: '', email: '' })
    setSearchResults([])
  }

  // Close MOdals on Error
  useEffect(() => {
    if (error?.hasError) {
      setConfirmDetailsModalOpen(false)
    }

    // eslint-disable-next-line
  }, [error?.hasError])

  useEffect(() => {
    if (searchResults[0]?.verification_status?.toUpperCase() === VerificationStatus.VERIFIED) {
      getPersonDetails().then((personalDetails) => {
        const { response } = personalDetails
        if (response.isSuccess) {
          const {
            data: { health_card_number },
          } = response
          setShowAddHealthCard(!!!health_card_number)
        } else {
          setShowAddHealthCard(false)
        }
      })
    }
    // eslint-disable-next-line
  }, [searchResults[0]?.user_id, searchResults[0]?.verification_status, searchResults[0]?.account_status])

  // Handle Searching Api call trigger
  useEffect(() => {
    if (isSearching) {
      handleFetchSearchApi()
    }

    // eslint-disable-next-line
  }, [isSearching])

  // Update Data from fetched json
  useEffect(() => {
    const dataFromValidator = () => {
      const result = updatedDataFromValidator(searchResults[0], validationBrokerData)
      // Update the validator fetched data
      const updatedResults = searchResults.map((item) => (item.user_id === result.user_id ? result : item))

      setSearchResults(updatedResults)
    }

    if (Object.keys(validationBrokerData).length) {
      dataFromValidator()
    }

    // eslint-disable-next-line
  }, [validationBrokerData])

  const handleReset2FAModalClose = () => {
    handleFetchSearchResults()
    setReset2FAModalOpen(false)
  }

  const handleResetEmailModalClose = (newEmail?: string) => {
    setSearchingError(false)
    setIsSearching(true)
    let formattedData = searchData
    if (newEmail) formattedData.email = newEmail
    setSearchData(formattedData)
    setResetEmailModalOpen(false)
  }

  const handleEditClick = (key: string) => {
    switch (key) {
      case 'email':
        setResetEmailModalOpen(true)
        break
      default:
        break
    }
  }

  // Search Click
  const handleFetchSearchResults = async () => {
    setSearchingError(false)
    setIsSearching(true)

    let formattedData = searchData

    if (!searchData?.email) {
      formattedData = {
        rin: searchData?.rin,
      }
    }

    setSearchData(formattedData)
  }

  const handleApiError = (error) => {
    if (!error || Object.keys(error).length === 0) return

    const errorType = error.isUnauthorizedError
      ? SESSION_TIMEOUT
      : error.isForbiddenError
      ? NOT_ENOUGH_PERMISSIONS
      : API_ERROR

    dispatch(updateError({ hasError: true, type: errorType }))
  }

  const handleFetchPersonHistory = async () => {
    try {
      if (searchResults[0]?.user_id) {
        const { response: personHistoryResponse, error: historyError } = await getPersonHistory(
          searchResults[0]?.user_id,
          personHistoryPage,
        )

        if (personHistoryResponse?.status === 200) {
          const history = personHistoryResponse?.data
          setPersronHistory(history)
        }

        handleApiError(historyError)
      }
    } catch (err) {
      console.log('err', err)
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    }
  }

  // Api Call to Fetch the Search Result
  const handleFetchSearchApi = async () => {
    try {
      const { response, error: apiError } = await searchApi()

      if (response?.isSuccess) {
        setSearchResults(response?.data)

        if (verifyPermission(permissions, AccountPermission.canViewAccountHistory)) {
          const { response: personHistoryResponse, error: historyError } = await getPersonHistory(
            response?.data[0]?.user_id,
            personHistoryPage,
          )

          if (personHistoryResponse?.status === 200) {
            const history = personHistoryResponse?.data
            setPersronHistory(history)
          }

          handleApiError(historyError)
        }
      }

      handleApiError(apiError)
    } catch (err) {
      console.log('err', err)
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    } finally {
      setSearchingError(true)
      setIsSearching(false)
    }
  }

  // Modal helper MEthods
  const toggleConfirmDetailsModal = () => setConfirmDetailsModalOpen(!isConfirmDetailsModalOpen)

  // PLaceholder account confirmation modal
  const handleCreateAccountModal = () => {
    // Error Cases foe email
    // If email is empty
    if (!searchData?.email) {
      return setErrorObj((prevInputs: any) => ({
        ...prevInputs,
        email: {
          hasError: true,
          errorMessage: 'SearchPage.ErrorMessages.required-field',
        },
      }))
    } else {
      // Valid Email Check
      if (!validateEmail(searchData?.email)) {
        return setErrorObj((prevInputs: any) => ({
          ...prevInputs,
          email: {
            hasError: true,
            errorMessage: 'SearchPage.ErrorMessages.invalid-email',
          },
        }))
      }
    }

    setConfirmDetailsModalOpen(true)
  }

  // Api Call to verfication for UNVERIFIED USER
  // Api Call for Creating a Placeholder Account
  const handleStartVerification = async () => {
    try {
      let formattedData: any = {
        user_id: searchResults[0]?.user_id,
        email: searchResults[0]?.email,
        rin: searchData?.rin,
      }

      // For placeholder Account dont add user_id
      if (!searchResults.length) {
        formattedData = {
          ...searchData,
        }
      }

      const { response, error: api_error } = await startVerification({
        data: formattedData,
      })

      if (response?.isSuccess) {
        // Open Link in a new tab
        window.open(response?.data?.link, '_blank')
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }))
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }))
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    } finally {
      // Close Placeholder on done
      setConfirmDetailsModalOpen(false)
    }
  }

  // Api Call to verfication for PENDING REVIEW
  const handleFetchVerification = async () => {
    try {
      const { response, error: api_error } = await fetchVerification()

      if (response?.isSuccess) {
        // Open Link in a new tab
        window.open(response?.data?.link, '_blank')
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }))
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }))
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    }
  }

  // Api Call to to FETCH SWAT DATA
  const handleFetchValidationBrokerData = async () => {
    try {
      const { response, error: api_error } = await fetchValidationBrokerData()

      if (response?.isSuccess) {
        setValidationBrokerData(response?.data)
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }))
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }))
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    }
  }

  // Api Call to FETCH verification code for printing
  const handleFetchVerificationCode = async () => {
    try {
      const { response, error: api_error } = await fetchVerificationCode()

      if (response?.isSuccess) {
        // Check Code Expiry
        if (response?.data?.expires_at > Date.now()) {
          // Open Link in a new tab
          // Add the data in the url params
          window.open(
            window.location.origin +
              window.GLOBAL_PATH +
              `${PrintSetupInstructionsRoute}?first_name=${response?.data?.first_name}&code=${response?.data?.code}&expiry_date=${response?.data?.expires_at}`,
            '_blank',
          )
        } else {
          // Open Code Expired Modal
          dispatch(updateError({ hasError: true, type: VERIFICATION_CODE_EXPIRED }))
        }
      }

      if (Object.keys(api_error).length) {
        if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
          dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }))
        } else {
          dispatch(updateError({ hasError: true, type: API_ERROR }))
        }
      }
    } catch (err) {
      dispatch(updateError({ hasError: true, type: API_ERROR }))
    }
  }

  const handleActionableBtns = () => {
    // Unverified Case
    const [searchResult] = searchResults
    const { account_status, verification_status } = searchResult

    if (
      account_status?.toUpperCase() === AccountStatus.ACTIVE &&
      verifyPermission(permissions, AccountPermission.canDeactivateAccount)
    ) {
      setActionOptions({
        deActiveAccount: {
          option: i18n.t('SearchPage.Actions.DeactivateAccount'),
          isSensitive: true,
          action: () => setDeacticeAccountModalOpen(true),
          dataTestId: 'button-deactivate-account',
        },
      })
    }

    if (
      account_status?.toUpperCase() === AccountStatus.DEACTIVATED &&
      verifyPermission(permissions, AccountPermission.canReActivateAccount)
    ) {
      setActionOptions({
        reActiveAccount: {
          option: i18n.t('SearchPage.Actions.ReactivateAccount'),
          isSensitive: true,
          action: () => setReacticeAccountModalOpen(true),
          dataTestId: 'button-reactivate-account',
        },
      })
    }

    if (
      verification_status?.toUpperCase() === VerificationStatus.UNVERIFIED &&
      verifyPermission(permissions, AccountPermission.canVerifyAccount)
    ) {
      setActionOptions({
        verifyIdentity: {
          option: i18n.t('SearchPage.SearchLabels.verify-identity-btn'),
          isSensitive: false,
          action: handleStartVerification,
          dataTestId: 'button-verify-identity-btn',
        },
      })
    }

    // Pending Review Case
    if (
      verification_status?.toUpperCase() === VerificationStatus.PENDING_REVIEW &&
      verifyPermission(permissions, AccountPermission.canVerifyAccount)
    ) {
      setActionOptions({
        verifyIdentity: {
          option: i18n.t('SearchPage.SearchLabels.verify-identity-btn'),
          isSensitive: false,
          action: handleFetchVerification,
          dataTestId: 'button-verify-identity-btn',
        },
      })
    }

    // Verified Case
    if (verification_status?.toUpperCase() === VerificationStatus.VERIFIED) {
      verifyPermission(permissions, AccountPermission.canFetchSWATData) &&
        setActionOptions({
          verifyBrokerData: {
            option: i18n.t('SearchPage.SearchLabels.update-data-from-swat-btn'),
            isSensitive: false,
            action: handleFetchValidationBrokerData,
            dataTestId: 'update-from-swat',
          },
        })

      verifyPermission(permissions, AccountPermission.canAddCR) &&
        account_status?.toUpperCase() === AccountStatus.ACTIVE &&
        showAddHealthCard &&
        setActionOptions({
          verifyHealthcard: {
            option: i18n.t('SearchPage.SearchLabels.verify-healthcard-btn'),
            isSensitive: false,
            action: handleIDVHealthCard,
            dataTestId: 'button-verify-hc',
          },
        })

      account_status?.toUpperCase() === AccountStatus.PLACEHOLDER &&
        setActionOptions({
          verifyCode: {
            option: i18n.t('SearchPage.SearchLabels.print-instructions-btn'),
            isSensitive: false,
            action: handleFetchVerificationCode,
            dataTestId: 'print-instructions',
          },
        })
    }

    if (
      !!config.feature_flags_enabled?.[Features.Reset2FA] &&
      verifyPermission(permissions, AccountPermission.canReset2FA) &&
      searchResults?.[0]?.account_status === AccountStatus.ACTIVE &&
      (!!searchResults?.[0]?.['2fa_reset'] || searchResults?.[0]?.authenticator !== 'EMAIL') &&
      !searchResults?.[0]?.email_reset
    ) {
      setActionOptions({
        reset2fa: {
          option: i18n.t('SearchPage.SearchLabels.reset-2fa-btn'),
          isSensitive: false,
          action: () => setReset2FAModalOpen(true),
          dataTestId: 'reset-2fa',
        },
      })
    }
  }

  useEffect(() => {
    if (searchResults?.length) {
      resetActionOptions()
      handleActionableBtns()
    }
    // eslint-disable-next-line
  }, [searchResults])

  useEffect(() => {
    if (verifyPermission(permissions, AccountPermission.canViewAccountHistory)) {
      handleFetchPersonHistory()
    }
    // eslint-disable-next-line
  }, [personHistoryPage])

  return (
    <>
      <Header title={i18n.t('SearchPage.title.AccountLookup')} showLogout />
      <div className={'searchpage'}>
        <Notification className="alert-msg" text={parse(i18n.t('SearchPage.alertMessage'))} />

        <section className="search">
          <SearchForm
            successFunc={handleFetchSearchResults}
            isLoading={isLoading}
            isSearchingSetState={setSearchingError}
            errorObj={errorObj}
            setErrorObj={setErrorObj}
            inputs={searchData}
            setInputs={setSearchData}
          />
        </section>

        {searchResults.length ? (
          <section className="search-results">
            <SearchResultTable
              data={searchResults}
              emptyMessage={i18n.t('SearchPage.no-result')}
              emptyBtnAction={handleCreateAccountModal}
              searchingError={searchingError}
              validationBrokerData={validationBrokerData}
              handleEditClick={handleEditClick}
            />
          </section>
        ) : null}
        {persronHistory?.data?.length ? (
          <AccountHistoryTable
            history={persronHistory}
            setPage={(page) => setPersonHistoryPage(page)}
            isLoading={isLoadingPersonHistory}
            page={personHistoryPage}
          />
        ) : null}
      </div>

      <ModalComp toggleModal={toggleConfirmDetailsModal} isModalOpen={isConfirmDetailsModalOpen}>
        <div className="confirm-details-modal" data-testid="confirm-details">
          {parse(
            i18n.t('SearchPage.confirm-details-modal', {
              rin: searchData?.rin,
              email: searchData?.email,
            }),
          )}

          <Button
            text={i18n.t('continue')}
            onClick={handleStartVerification}
            isLoading={isLoadingStartVerification}
            dataTestId={'button-primary'}
          />
        </div>
      </ModalComp>
      <Reset2FAModal
        userId={searchResults?.[0]?.user_id}
        resetExpiryDuration={
          !!searchResults?.[0]?.duration ? convertSecondsToHumanReadableTime(searchResults[0].duration) : undefined
        }
        isOpen={isReset2FAModalOpen}
        close={handleReset2FAModalClose}
      />
      <ResetEmailModal
        userId={searchResults?.[0]?.user_id}
        userEmail={searchResults?.[0]?.email}
        isOpen={isResetEmailModalOpen}
        resetExpiryDuration={
          !!searchResults?.[0]?.duration ? convertSecondsToHumanReadableTime(searchResults[0].duration) : undefined
        }
        close={handleResetEmailModalClose}
      />
      <DeactiveAccountModal
        userId={searchResults?.[0]?.user_id}
        isOpen={isDeacticeAccountModalOpen}
        close={() => setDeacticeAccountModalOpen(false)}
        onFinish={async () => await handleFetchSearchApi()}
      />
      <ReactiveAccountModal
        userId={searchResults?.[0]?.user_id}
        isOpen={isReacticeAccountModalOpen}
        close={() => setReacticeAccountModalOpen(false)}
        onFinish={async () => await handleFetchSearchApi()}
      />
    </>
  )
}

export default SearchPage
