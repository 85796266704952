import React from 'react'
import i18n from '../../../i18n'
import { PersonHistoryRequest } from '../../../components/ComponentInterface'
import { ReactComponent as ArrowNext } from '../../../asset/icon-arrow-next.svg'
import { ReactComponent as ArrowNextDisabled } from '../../../asset/icon-arrow-next-disabled.svg'
import { ReactComponent as ArrowBack } from '../../../asset/icon-arrow-back.svg'
import { ReactComponent as ArrowBackDisabled } from '../../../asset/icon-arrow-back-disabled.svg'

interface IAccountHistoryProps {
  history: PersonHistoryRequest
  setPage: (page: number) => void
  page: number
  isLoading: boolean
}
export default function AccountHistoryTable({ history, page, setPage }: IAccountHistoryProps) {
  const { data, total_elements, total_per_page, total_pages } = history

  const handleNextPage = () => {
    if (page < total_pages - 1) setPage(page + 1)
  }
  const handleBackPage = () => {
    if (page > 0) setPage(page - 1)
  }

  return (
    <div className="account-history-container">
      <div className="search-table">
        <div className="table-header">
          <div className="table-title">
            <h2 className="title">{i18n.t('SearchPage.title.AccountHistory')}</h2>
          </div>
        </div>

        <table className="account-history-table">
          <thead>
            <tr>
              <th style={{ borderRight: '1px solid #CCCCCC' }} key={`head-column-Date`}>
                {i18n.t('SearchPage.account-history.Date')}
              </th>
              <th style={{ borderRight: '1px solid #CCCCCC' }} key={`head-column-Action`}>
                {i18n.t('SearchPage.account-history.Action')}
              </th>
              <th style={{ borderRight: '1px solid #CCCCCC' }} key={`head-column-Admin`}>
                {i18n.t('SearchPage.account-history.Admin')}
              </th>
              <th key={`head-column-Notes`}>{i18n.t('SearchPage.account-history.Notes')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(({ administrator, date, details, event_type }) => {
              return (
                <tr>
                  <td key={`body-row-1`}>{date}</td>
                  <td key={`body-row-2`}>{event_type}</td>
                  <td key={`body-row-3`}>{administrator}</td>
                  <td style={{ maxWidth: '418px', overflowWrap: 'break-word' }} key={`body-row-4`}>
                    {details?.reason || '--'}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div>{`${page === 0 ? '1' : page * 10 + 1} - ${page * 10 + total_per_page} of ${total_elements} actions`}</div>
        <div className="actions">
          <div className="btn-page" onClick={handleBackPage}>
            {page === 0 ? <ArrowBackDisabled /> : <ArrowBack />}
          </div>
          <div onClick={handleNextPage}>{page + 1 === total_pages ? <ArrowNextDisabled /> : <ArrowNext />}</div>
        </div>
      </div>
    </div>
  )
}
