import React, { FC, HTMLAttributes, PropsWithChildren } from 'react'
import i18n from '../../../i18n'
import { maskingFields } from '../../../utils/SearchPage'
import AutoWidthInput from '../Components/AutoWidthInput'
import { removeDashSpaceSpecialChar } from '../../../utils'

type ButtonOrDivProps = PropsWithChildren &
  HTMLAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLButtonElement> & {
    onClick?: () => void
    dataTestId?: string
  }
/**
 * Returns a button if you provide an onClick prop or a div if you don't
 */
const ButtonOrDiv: FC<ButtonOrDivProps> = ({ onClick, children, dataTestId, ...props }) => {
  return !!onClick ? (
    <button data-testid={dataTestId} onClick={onClick} {...props}>
      {children}
    </button>
  ) : (
    <div data-testid={dataTestId} {...props}>
      {children}
    </div>
  )
}

const handleValueSanitization = (key: string, value: string) => {
  if (value !== null && value !== 'null' && value !== '') {
    if (key === 'postal_code') {
      return removeDashSpaceSpecialChar(value)
    }

    return value
  } else {
    return '--'
  }
}

type DataEntryProps = {
  key_string: string
  value: string
  editable?: boolean
  onEdit?: () => void
}

const DataEntry: FC<DataEntryProps> = ({ key_string, value, editable, onEdit }) => {
  if (!key_string) return <td />

  return (
    <td className={!!editable ? 'no-padding' : undefined} data-testid={key_string}>
      <ButtonOrDiv
        dataTestId={editable ? `${key_string}-edit` : undefined}
        className="item"
        onClick={!!editable ? onEdit : undefined}
      >
        <strong className="desc">{i18n.t(key_string)}</strong>
        <AutoWidthInput
          mask={!!value ? maskingFields(key_string) : ''}
          initialValue={handleValueSanitization(key_string, value)}
          readOnly={true}
        />
        {!!editable && <p className="edit">{i18n.t('edit')}</p>}
      </ButtonOrDiv>
    </td>
  )
}

export default DataEntry
