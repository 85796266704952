import React from 'react'

const AccountInfo = ({ title, value }: { title: string; value: string | React.ReactNode }) => (
  <div className="account-info">
    <div className="title">{title}</div>
    <div className="value">{value || '--'}</div>
  </div>
)

export default AccountInfo
