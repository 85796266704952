import { useEffect, useState } from 'react'
import { useContext } from '../../../context'
import { setStateActionOptions } from '../../../context/actions/ConfigActions'

export default function useActionsOptions() {
  const {
    state: { actionOptions },
    dispatch,
  } = useContext()

  const [actionTempState, setActionTempState] = useState({})

  useEffect(() => {
    dispatch(setStateActionOptions({ ...actionTempState }))
    // eslint-disable-next-line
  }, [actionTempState])

  const setActionOptions = (action) => {
    setActionTempState((prev) => {
      return { ...prev, ...action }
    })
  }

  const resetActionOptions = () => {
    setActionTempState({})
    setStateActionOptions({})
  }

  return { actionOptions, setActionOptions, resetActionOptions }
}
