const setFromLocal = (state: any) => ({ type: 'SET_FROM_LOCAL', payload: state })

const updateConfig = (config: any) => ({ type: 'UPDATE_CONFIG', payload: config })

const updateError = (error: any) => ({ type: 'UPDATE_ERROR', payload: error })

const updateToken = (token?: string) => ({ type: 'UPDATE_TOKEN', payload: token })

const updateProfile = (profile: any) => ({ type: 'UPDATE_PROFILE', payload: profile })

const setStateActionOptions = (options: any) => ({ type: 'SET_ACTION_OPTIONS', payload: options })

export { setFromLocal, setStateActionOptions, updateConfig, updateError, updateToken, updateProfile }
