import { useTriggeredActivateAccount } from '../../../services/AdminServices'

export default function useReactiveAccount(userId: string) {
  const { postActiveAccount, isLoading } = useTriggeredActivateAccount(userId)

  const handleReactiveAccount = async (reason) => {
    try {
      await postActiveAccount({ data: { reason } })
    } catch (e) {}
  }

  return { handleReactiveAccount, isLoading }
}
