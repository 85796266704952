import React, { FC } from "react";
import ReactModal from "react-modal";
import i18n from "../../../../i18n";
import { ReactComponent as Close } from "../../../../asset/icon-close-white.svg";
import { Button } from "../../../../components";
import { useTriggeredReset2FA } from "../../../../services/AdminServices";

type Reset2FAModalProps = {
  isOpen: boolean;
  close: () => void;
  userId?: string;
  resetExpiryDuration?: string;
};

const Reset2FAModal: FC<Reset2FAModalProps> = ({
  isOpen,
  close,
  userId,
  resetExpiryDuration,
}) => {
  const { reset2FA, isLoading } = useTriggeredReset2FA(userId || "");
  const handleReset2FA = async () => {
    try {
      await reset2FA();
    } catch (e) {
      console.error("Error when resetting 2FA");
    } finally {
      close();
    }
  };
  return (
    <ReactModal
      overlayClassName="ReactModal__Overlay"
      isOpen={isOpen}
      className="modal reset-2fa-modal"
      testId="reset-2fa-modal"
    >
      <div className="header">
        <h2>{i18n.t("reset-item", { item: i18n.t("2FA-method") })}</h2>
        <button data-testid='reset-2fa-close' onClick={close}>
          <Close />
        </button>
      </div>
      <div className="content">
        <p>
          {i18n.t("reset-2fa-modal-warning", { duration: resetExpiryDuration })}
        </p>
        <div className="actions">
          <Button dataTestId="reset-2fa-cancel" onClick={close} text={i18n.t("cancel")} />
          <Button
            dataTestId="reset-2fa-submit"
            onClick={handleReset2FA}
            className="border-btn"
            isLoading={isLoading}
            text={i18n.t("reset")}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default Reset2FAModal;
