import React from 'react'
import { AccountPermission } from '../../../../common/enum'
import { Button } from '../../../../components'
import i18n from '../../../../i18n'
import { verifyPermission } from '../../../../utils'

export const EmptyState = ({ emptyMessage, permissions, emptyBtnAction }) => (
  <>
    <p className="empty-list" data-testid="no-results">
      {emptyMessage}
    </p>
    {verifyPermission(permissions, AccountPermission.canVerifyAccount) && (
      <Button
        text={i18n.t('SearchPage.create-account-button')}
        onClick={emptyBtnAction}
        className="create-account-btn"
        dataTestId="button-create-acc-start-verification"
      />
    )}
  </>
)
