import { AccountStatus } from '../common/enum'
import i18n from '../i18n'

export const currentAccountStatus = (requestStatus?: string) => {
  let status: string = ''
  let tempClassName: string = ''

  switch (requestStatus) {
    case AccountStatus.ACTIVE:
      status = i18n.t('SearchPage.SearchLabels.Statuses.active')
      tempClassName = 'active'
      break

    case AccountStatus.DEACTIVATED:
      status = i18n.t('SearchPage.SearchLabels.Statuses.deactivated')
      tempClassName = 'unverified'
      break

    case AccountStatus.INACTIVE:
      status = i18n.t('SearchPage.SearchLabels.Statuses.inactive')
      tempClassName = 'inactive'
      break

    case AccountStatus.PLACEHOLDER:
      status = i18n.t('SearchPage.SearchLabels.Statuses.placeholder')
      tempClassName = 'placeholder'
      break
    default:
      break
  }

  return { className: tempClassName, status }
}

export const currentVerificationStatus = (requestStatus?: string) => {
  let status: string = ''
  let tempClassName: string = ''

  switch (requestStatus) {
    case 'VERIFIED':
      status = i18n.t('SearchPage.SearchLabels.Statuses.verified')
      tempClassName = 'verified'
      break

    case 'UNVERIFIED':
      status = i18n.t('SearchPage.SearchLabels.Statuses.unverified')
      tempClassName = 'unverified'
      break

    case 'PENDING_REVIEW':
      status = i18n.t('SearchPage.SearchLabels.Statuses.pending_review')
      tempClassName = 'pendingReview'
      break
    default:
      break
  }

  return { className: tempClassName, status }
}

export const updatedDataFromValidator = (orginalData: any, validationBrokerData: any) => {
  let updatedData = orginalData
  if (Object.keys(validationBrokerData).length && Object.keys(orginalData).length) {
    // Loop through Each Field
    Object.keys(validationBrokerData).forEach((key) => {
      if (key !== 'id') {
        if (updatedData?.[key]) {
          updatedData[key] = validationBrokerData[key]
        }
      }
    })
  }

  return updatedData
}

export const maskingFields = (key: string) => {
  let masking = ''

  switch (key) {
    case 'id_card_expiry':
      masking = '9999-99-99'
      break
    case 'birth_date':
      masking = '9999-99-99'
      break
    case 'health_card_expiry':
      masking = '9999-99-99'
      break
    case 'postal_code':
      masking = 'a9a 9a9'
      break
    default:
      break
  }

  return masking
}
