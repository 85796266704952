import React, { useState, useCallback } from 'react'
import useActionsOptions from '../../hooks/useActionsOptions'
import { IActionOption } from '../../../../components/ComponentInterface'

interface ActionDropdownProps {
  isOpen: boolean
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({ isOpen }) => {
  const { actionOptions } = useActionsOptions()
  const [isLoading, setIsLoading] = useState(false)

  const options = Object.values(actionOptions) as Array<IActionOption>

  const styles = {
    container: {
      position: 'absolute' as const,
      margin: '16px 20px 0px 0px',
      backgroundColor: 'white',
      border: '2px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      right: '80px',
    },
    option: (isSensitive: boolean) => ({
      padding: '10px 20px',
      cursor: 'pointer',
      borderBottom: '1px solid #f0f0f0',
      color: isSensitive ? '#B30900' : '#3A3A3A',
    }),
  }

  const handleAction = useCallback(async (action?: () => void) => {
    if (!action) return
    setIsLoading(true)
    try {
      await action()
    } catch (error) {
      console.error('Action error:', error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  if (!isOpen) return null

  return (
    <div style={styles.container}>
      {isLoading ? (
        <div style={{ padding: '10px 20px', textAlign: 'center' }}>Loading...</div>
      ) : options.length ? (
        options.map(({ option, isSensitive, dataTestId, action }) => (
          <div
            key={option}
            data-testid={dataTestId}
            style={styles.option(isSensitive)}
            onClick={() => handleAction(action)}
          >
            {option}
          </div>
        ))
      ) : (
        <div style={{ padding: '10px 20px', textAlign: 'center' }}>No options available</div>
      )}
    </div>
  )
}

export default ActionDropdown
