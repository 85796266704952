import axios, { get } from './axios'
import queryString from 'query-string'
import { useState, useEffect } from 'react'
import { ApiI } from './interface'
import { AxiosRequestConfig } from 'axios'
import { useContext } from '../context'

export const baseURL = async () => (await get(`${process.env.PUBLIC_URL}/config/config.json`))?.data.base_url
export const getConfig = async () => await get(`./config/config.json`)
export const getHash = (hash: string) =>
  new URLSearchParams(window.location.hash).get(`#${hash}`) ||
  (window.sessionStorage.getItem('admin') ? JSON.parse(window.sessionStorage.getItem('admin') || '""')?.[hash] : '')
export const getResetToken = () => new URLSearchParams(window.location.hash).get('reset_token')
export const getQuerySearch = () => queryString.parse(window.location.search)
export const getDataFromURL = async (url: string) => await get(url)

const useGenericRequest = () => {
  const {
    state: {
      config: { base_url },
    },
  } = useContext()

  const [isLoading, setIsLoading] = useState(false)

  const endpoint = async ({ data, url, method, requiresAuth = true }) => {
    try {
      setIsLoading(true)

      let axiosParameters: AxiosRequestConfig = {
        method,
        url: base_url + url,
        data,
      }

      if (requiresAuth === true) {
        axiosParameters.withCredentials = true
      }

      const response: any = await axios(axiosParameters)

      console.log('useApi result', response)
      if (method === 'get' || method === 'put') {
        response.isSuccess = response?.status === 200
      }
      if (method === 'post') {
        response.isSuccess = response?.status === 200
        response.isSuccessValidate = response?.status === 200 && response?.data?.statusCode === 1
        response.isValidateError = response?.status === 200 && response?.data?.statusCode === 2
        response.isAttemptError = response?.status === 200 && response?.data?.statusCode === 3
        response.isDuplicateError = response?.status === 200 && response?.data?.statusCode === 4
        response.isRedirect = response?.status === 302
      }
      return {
        response,
        error: {},
      }
    } catch (error: any) {
      error.isInvalidError = error.response?.status === 400
      error.isUnauthorizedError = error.response?.status === 401
      error.isForbiddenError = error.response?.status === 403
      error.isNotFoundError = error.response?.status === 404
      error.isSessionTimedOutError = error.response?.status === 408
      error.isConflictError = error.response?.status === 409
      error.isSessionTimeoutError = error.response?.status === 410
      error.isTooManyAttemptError = error.response?.status === 429
      error.isInternalServerError = error.response?.status === 500
      error.isGeneralError = error.response?.status !== 200 && error.response?.status !== 408
      return {
        response: {},
        error,
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { endpoint, isLoading }
}

const useApi = ({ url, method, requiresAuth = true, multipartForm }: ApiI) => {
  const {
    state: {
      config: { base_url },
    },
  } = useContext()
  const [isLoading, setIsLoading] = useState(false)

  const endpoint = async ({ data } = { data: {} }) => {
    try {
      setIsLoading(true)

      let axiosParameters: AxiosRequestConfig = {
        method,
        url: base_url + url,
        data,
      }

      if (requiresAuth === true) {
        axiosParameters.withCredentials = true
      }

      if (multipartForm === true) {
        axiosParameters.headers = { ...axiosParameters.headers, 'Content-Type': 'multipart/form-data' }
      }

      const response: any = await axios(axiosParameters)

      console.log('useApi result', response)
      if (method === 'get' || method === 'put') {
        response.isSuccess = response?.status === 200
      }
      if (method === 'post') {
        response.isSuccess = response?.status === 200
        response.isSuccessValidate = response?.status === 200 && response?.data?.statusCode === 1
        response.isValidateError = response?.status === 200 && response?.data?.statusCode === 2
        response.isAttemptError = response?.status === 200 && response?.data?.statusCode === 3
        response.isDuplicateError = response?.status === 200 && response?.data?.statusCode === 4
        response.isRedirect = response?.status === 302
      }
      return {
        response,
        error: {},
      }
    } catch (error: any) {
      error.isInvalidError = error.response?.status === 400
      error.isUnauthorizedError = error.response?.status === 401
      error.isForbiddenError = error.response?.status === 403
      error.isNotFoundError = error.response?.status === 404
      error.isSessionTimedOutError = error.response?.status === 408
      error.isConflictError = error.response?.status === 409
      error.isSessionTimeoutError = error.response?.status === 410
      error.isTooManyAttemptError = error.response?.status === 429
      error.isInternalServerError = error.response?.status === 500
      error.isGeneralError = error.response?.status !== 200 && error.response?.status !== 408
      return {
        response: {},
        error,
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { endpoint, isLoading }
}

// note: the only thing that returns response data: isRedirect

const useTriggeredGet = ({ url, requiresAuth = true }: ApiI) => useApi({ url, method: 'get', requiresAuth })
const useTriggeredPost = ({ url, requiresAuth = true, multipartForm }: ApiI) =>
  useApi({ url, method: 'post', requiresAuth, multipartForm })
const useTriggeredPut = ({ url, requiresAuth = true }: ApiI) => useApi({ url, method: 'put', requiresAuth })

const useGet = ({ url, data = {}, requiresAuth = true }: ApiI) => {
  const [getResponse, setGetResponse] = useState<any>(null)
  const [getError, setGetError] = useState<any>(null)

  const { endpoint, isLoading } = useApi({ url, method: 'get', requiresAuth })

  useEffect(() => {
    const callGetEndpoint = async () => {
      const result: any = await endpoint({ data })
      if (Object.keys(result.error).length) {
        setGetError(result.error)
      } else {
        setGetResponse(result.response)
      }
    }
    callGetEndpoint()
    // eslint-disable-next-line
  }, [])

  return {
    response: getResponse,
    error: getError,
    isLoading,
  }
}

const usePost = ({ url, data = {}, requiresAuth = true, multipartForm }: ApiI) => {
  const [postResponse, setPostResponse] = useState<any>(null)
  const [postError, setPostError] = useState<any>(null)

  const { endpoint, isLoading } = useApi({ url, method: 'post', requiresAuth, multipartForm })

  useEffect(() => {
    const callPostEndpoint = async () => {
      const result: any = await endpoint({ data })
      if (Object.keys(result.error).length) {
        setPostError(result.error)
      } else {
        setPostResponse(result.response)
      }
    }
    callPostEndpoint()
    // eslint-disable-next-line
  }, [])

  return {
    response: postResponse,
    error: postError,
    isLoading,
  }
}

const usePut = ({ url, data = {}, requiresAuth = true }: ApiI) => {
  const [putResponse, setPutResponse] = useState<any>(null)
  const [putError, setPutError] = useState<any>(null)

  const { endpoint, isLoading } = useApi({ url, method: 'put', requiresAuth })

  useEffect(() => {
    const callPutEndpoint = async () => {
      const result: any = await endpoint({ data })
      if (Object.keys(result.error).length) {
        setPutError(result.error)
      } else {
        setPutResponse(result.response)
      }
    }
    callPutEndpoint()
    // eslint-disable-next-line
  }, [])

  return {
    response: putResponse,
    error: putError,
    isLoading,
  }
}

export { useApi, useTriggeredGet, useTriggeredPost, useTriggeredPut, useGet, usePost, usePut, useGenericRequest }
