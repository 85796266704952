import React from 'react'
import { currentAccountStatus, currentVerificationStatus } from '../../../utils/SearchPage'

export const VerificationStatusCell = ({ verificationStatus }: { verificationStatus?: string }) => {
  const { status, className } = currentVerificationStatus(verificationStatus?.toUpperCase())
  return (
    <td className="last-row" data-testid="verification-status">
      <p className={`status ${className}`}>{status}</p>
    </td>
  )
}

export const AccountStatusCell = ({ accountStatus }: { accountStatus?: string }) => {
  const { status, className } = currentAccountStatus(accountStatus?.toUpperCase())
  return (
    <td data-testid="account-status" className="nowrap">
      <p className={`status ${className}`}>{status}</p>
    </td>
  )
}
